import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise } from 'axios';
import {
  ClientCreatePayload,
  Profile,
  Tokens,
  ProfileAdditionalInfoPayload,
  ClientChangePassword,
  PasswordResetParams,
  ClientChangeEmail,
  ClientChangePhone,
  ProfileSections,
  PersonalInfo
} from './models/client';
import { ClientSigningAgreement } from '@/api/models/client';
import { ActivityData } from '@/api/models/document';
import { Address } from '@/api/models/address';
import { IdentityDocument } from '@/api/models/itentityDocument';
import { ClientDisbursement } from '@/api/models/disbursement';

type WithType<T = string> = { type: T };
type ResponseBase<TData extends WithType> = TData & {
  type: TData['type'];
  id: number;
  current: boolean;
  created: ActivityData;
};

export type ResponseList<TData extends WithType> = Array<ResponseBase<TData>>;
export type ResponseMap<TData extends WithType> = {
  [key in TData['type']]: ResponseList<TData>;
};

export const createClient = (data: ClientCreatePayload): AxiosPromise<Profile> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: '/client/clients',
    method: HTTP_METHODS.POST,
    data
  });

export const login = (data: string): AxiosPromise<Tokens> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: '/client/auth/login',
    method: HTTP_METHODS.POST,
    data
  });

export const refreshTokens = (refreshToken: string): AxiosPromise<Tokens> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: '/refresh',
    method: HTTP_METHODS.POST,
    headers: {
      'X-Auth-Refresh-Token': refreshToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: ''
  });

export const getProfile = (sections?: ProfileSections): AxiosPromise<Profile> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile',
    method: HTTP_METHODS.GET,
    params: sections
  });

export const updatePersonalInfo = (data: PersonalInfo): AxiosPromise<Profile> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/personal-info',
    method: HTTP_METHODS.PUT,
    data
  });

export const saveAdditionalInfo = (data: ProfileAdditionalInfoPayload): AxiosPromise<Profile> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/additional-info',
    method: HTTP_METHODS.PUT,
    data
  });

export type AddressResponse = ResponseBase<Address>;
export const getAddress = (): AxiosPromise<AddressResponse[]> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/addresses',
    method: HTTP_METHODS.GET
  });

export const saveAddress = (data: Address): AxiosPromise<AddressResponse> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/addresses',
    method: HTTP_METHODS.POST,
    data
  });

type IdentityDocumentsResponse = ResponseBase<IdentityDocument>;

export const saveIdentityDocuments = (data: IdentityDocument): AxiosPromise<IdentityDocumentsResponse> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/identity-documents',
    method: HTTP_METHODS.POST,
    data
  });

export type DisbursementResponse = ResponseBase<ClientDisbursement>;
export type DisbursementsResponseList = Array<DisbursementResponse>;
export const getDisbursementType = (): AxiosPromise<DisbursementsResponseList> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/disbursements',
    method: HTTP_METHODS.GET
  });

export const saveDisbursementType = (
  data: ClientDisbursement,
  markAsCurrent = false
): AxiosPromise<DisbursementResponse> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/disbursements',
    method: HTTP_METHODS.POST,
    data: { ...data, markAsCurrent: markAsCurrent }
  });

export const saveSigningAgreement = (data: ClientSigningAgreement): AxiosPromise<ClientSigningAgreement> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/agreement-signing-method',
    method: HTTP_METHODS.PUT,
    data
  });

export const changePassword = (data: ClientChangePassword) => {
  return request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/change-password',
    method: HTTP_METHODS.PUT,
    data
  });
};

export const changeEmail = (data: ClientChangeEmail) => {
  return request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/change-email',
    method: HTTP_METHODS.PUT,
    data
  });
};

export const changePhone = (data: ClientChangePhone) => {
  return request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/client/profile/mobile-phone',
    method: HTTP_METHODS.PUT,
    data
  });
};

export const recoveryPasswordReset = (password: string, params: PasswordResetParams) => {
  return request({
    baseURL: process.env.VUE_APP_BASE_API_ANON,
    url: `/client/password-recovery/reset`,
    method: HTTP_METHODS.POST,
    data: {
      newPassword: password
    },
    params
  });
};
