import { RouteConfig } from 'vue-router';
import { trimEnd } from 'lodash-es';
import Home from '@/views/Home.vue';
import { calculatorMiddleware } from '@/router/middleware/calculator';
import LoginPage from '@/views/Login.vue';
import { guest } from '@/router/middleware/guest';
import { passwordReset } from '@/router/middleware/passwordReset';
import { passwordRecoveryProcess } from '@/router/middleware/passwordRecoveryProcess';
import { auth } from '@/router/middleware/auth';
import { initLoanMiddleware, noActiveAgreementsMiddleware } from '@/router/middleware/agreement';
import ContactsPage from '@/views/Contacts.vue';
import CreditForBusinessPage from '@/views/CreditForBusiness.vue';
import DocumentsPage from '@/views/Documents.vue';
import ReportsPage from '@/views/ReportsPage.vue';
import NewsPage from '@/views/News/index.vue';
import NewsInfoPage from '@/views/NewsInfo/index.vue';
import { APPLICATION_ROUTES, MULTI_LOAN_ROUTES, PROFILE_ROUTES, REGISTRATION_ROUTES } from '@/router/routes_type';
import { checkRouteMiddleware } from '@/router/middleware/checkRoute';
import { impersonate } from '@/router/middleware/impersonate';
import RegistrationVerigram from '@/views/registration/RegistrationVerigram.vue';

export const SKIP_ROUTE_CHECK = 'skipRouteCheck';
export const SEND_PROGRESS = 'sendProgress';
export const LOGOUT = 'logout';

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: APPLICATION_ROUTES.HOME_PAGE,
    component: Home,
    meta: {
      middleware: [impersonate, calculatorMiddleware]
    }
  },
  {
    path: '/verigram',
    name: REGISTRATION_ROUTES.VERIGRAM,
    component: RegistrationVerigram,
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      visibleInNav: true
    }
  },
  {
    path: '/login',
    name: APPLICATION_ROUTES.LOGIN_PAGE,
    component: LoginPage,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/password-recovery',
    name: APPLICATION_ROUTES.PASSWORD_FORGOT,
    component: () =>
      import(
        /* webpackChunkName: "password-recovery" */
        // '@/views/PasswordForgot/PasswordForgot.vue' since phone recovery temporary disabled
        '@/views/PasswordForgot/MethodEmail.vue'
      )
  },
  {
    path: '/password-recovery/email',
    name: 'PasswordForgotEmail',
    component: () =>
      import(
        /* webpackChunkName: "password-recovery" */
        '@/views/PasswordForgot/MethodEmail.vue'
      )
  },
  {
    path: '/password-recovery/phone',
    name: 'PasswordForgotPhone',
    redirect: () => {
      return { path: '/password-recovery' }
    },
    // @todo: if you want to add phone recovery back, search all commented blocks by "phone recovery temporary disabled"
    // remove redirect if adding this functionality back
    // component: () =>
    //   import(
    //     /* webpackChunkName: "password-recovery" */
    //     '@/views/PasswordForgot/MethodPhone.vue'
    //   )
  },
  {
    path: '/password-recovery/verification',
    name: 'PasswordForgotVerification',
    component: () =>
      import(
        /* webpackChunkName: "password-recovery" */
        '@/views/PasswordForgot/PasswordForgotVerification.vue'
      ),
    meta: {
      middleware: [passwordRecoveryProcess]
    }
  },
  {
    path: '/password-recovery/reset',
    name: 'PasswordReset',
    component: () =>
      import(
        /* webpackChunkName: "password-recovery" */
        '@/views/PasswordForgot/PasswordResetForm.vue'
      ),
    meta: {
      middleware: [passwordReset]
    }
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Profile.vue'),
    children: [
      {
        path: 'my-loan/:verificationId?',
        name: PROFILE_ROUTES.MY_LOAN_PAGE,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/MyLoan.vue'),
        meta: {
          middleware: [auth, initLoanMiddleware]
        }
      },
      {
        path: 'no-loan',
        name: PROFILE_ROUTES.NO_LOAN_PAGE,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/NoLoan.vue'),
        meta: {
          middleware: [auth, noActiveAgreementsMiddleware]
        }
      },
      {
        path: 'change-password',
        name: PROFILE_ROUTES.CHANGE_PASSWORD_PAGE,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ChangePassword/index.vue'),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'change-email',
        name: PROFILE_ROUTES.CHANGE_EMAIL_PAGE,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ChangeEmail/index.vue'),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'change-phone',
        name: PROFILE_ROUTES.CHANGE_PHONE_PAGE,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ChangePhone/index.vue'),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'documents',
        name: PROFILE_ROUTES.DOCUMENTS_PAGE,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Documents/index.vue'),
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'info',
        name: PROFILE_ROUTES.EDIT_PAGE,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Edit/index.vue'),
        meta: {
          middleware: [auth]
        }
      }
    ],
    meta: {
      middleware: [auth, initLoanMiddleware]
    }
  },
  {
    path: '/registration/personal-data',
    name: REGISTRATION_ROUTES.PERSONAL_DATA,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationPersonalDataPage.vue'),
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/registration/additional-info',
    name: REGISTRATION_ROUTES.ADDITIONAL_INFO,
    component: () =>
      import(
        /* webpackChunkName: "registration" */ '@/views/registration/AdditionalInfo/RegistrationAdditionalInfoPage.vue'
      ),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/take-loan',
    name: REGISTRATION_ROUTES.CALCULATOR,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationCalculatorPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware, calculatorMiddleware]
    }
  },
  {
    path: '/registration/documents',
    name: REGISTRATION_ROUTES.DOCUMENTS_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationDocumentsPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: `/registration/disbursement-type/:verificationId?`,
    name: REGISTRATION_ROUTES.DISBURSEMENT_TYPE_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationDisbursementTypePage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: `/next-loan/disbursement-type/:verificationId?`,
    name: MULTI_LOAN_ROUTES.DISBURSEMENT_TYPE_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/multiloan/MultiLoanDisbursementTypePage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/offer-decrease',
    name: REGISTRATION_ROUTES.OFFER_DECREASE_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationOfferDecreasePage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/next-loan/income',
    name: MULTI_LOAN_ROUTES.INCOME_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/multiloan/MultiLoanIncomeInfoPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/next-loan/calculator',
    name: MULTI_LOAN_ROUTES.CALCULATOR,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/multiloan/MultiLoanCalculatorPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware, calculatorMiddleware]
    }
  },
  {
    path: '/registration/summary',
    name: REGISTRATION_ROUTES.SUMMARY_PAGE,
    component: () => import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationSummaryPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware, calculatorMiddleware]
    }
  },
  {
    path: '/next-loan/summary',
    name: MULTI_LOAN_ROUTES.SUMMARY_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/multiloan/MultiLoanSummaryPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware, calculatorMiddleware]
    }
  },
  {
    path: '/registration/offer-increase',
    name: REGISTRATION_ROUTES.OFFER_INCREASE_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationOfferIncreasePage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/decision-loading',
    name: REGISTRATION_ROUTES.DECISION_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationDecisionLoadingPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/income-reupload',
    name: REGISTRATION_ROUTES.INCOME_REUPLOAD_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationIncomeReuploadPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/application-loading',
    name: REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE,
    component: () =>
      import(
        /* webpackChunkName: "registration" */ '@/views/registration/RegistrationManualVerificationLoadingPage.vue'
      ),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/agreement-signing',
    name: REGISTRATION_ROUTES.AGREEMENT_SIGNING_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationAgreementSigningPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/agreement-online-signing',
    name: REGISTRATION_ROUTES.AGREEMENT_ONLINE_SIGNING_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationAgreementOnlineSigningPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/registration/application-rejected',
    name: REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE,
    component: () =>
      import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationApplicationRejectPage.vue'),
    meta: {
      middleware: [auth, checkRouteMiddleware]
    }
  },
  {
    path: '/how-to-return',
    component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/index.vue'),
    children: [
      {
        path: '',
        name: 'HowToReturnPage',
        component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/AllReturnMethods.vue'),
        meta: {
          middleware: [calculatorMiddleware]
        }
      },
      {
        path: 'kassa24-terminal',
        name: 'Kassa24Terminal',
        component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/Kassa24Terminal.vue')
      },
      {
        path: 'card-payment',
        name: 'CardPayment',
        component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/CardPayment.vue')
      },
      {
        path: 'online-banking',
        name: 'OnlineBanking',
        component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/OnlineBanking.vue')
      },
      {
        path: 'qiwi-terminal',
        name: 'QiwiTerminal',
        component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/QiwiTerminal.vue')
      },
      {
        path: 'qiwi-wallet',
        name: 'QiwiWallet',
        component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/QiwiWallet.vue')
      },
      {
        path: 'payment-bank-desk',
        name: 'PaymentBankDesk',
        component: () => import(/* webpackChunkName: "how-to-return" */ '@/views/HowToReturn/PaymentBankDesk.vue')
      }
    ]
  },
  {
    path: '/contacts',
    name: 'ContactsPage',
    component: ContactsPage
  },
  {
    path: '/credit-for-business',
    name: 'CreditForBusinessPage',
    component: CreditForBusinessPage
  },
  {
    path: '/documents',
    name: 'DocumentsPage',
    component: DocumentsPage
  },
  {
    path: '/reports',
    name: 'ReportsPage',
    component: ReportsPage
  },
  {
    path: '/news',
    name: 'NewsPage',
    component: NewsPage
  },
  {
    path: '/news/:articleId',
    name: 'NewsInfoPage',
    component: NewsInfoPage,
    props: true
  }
];

type TRoutes = typeof routes;
export const flatRouter = (routeList: TRoutes, parent?: RouteConfig): TRoutes => {
  const parentPath = parent ? trimEnd(parent?.path, '/') + '/' : '';
  return routeList
    .map(({ children = [], ...item }) =>
      [
        {
          ...item,
          path: parentPath + item.path
        },
        flatRouter(children, item)
      ].flat()
    )
    .flat();
};
