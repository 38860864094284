
import { Vue, Component, Prop } from 'vue-property-decorator';
import ProfileNavBarLinks from '@/components/Profile/ProfileNavBarLinks/index.vue';
import { Location } from 'vue-router';
import ProfileNavBarLogOut from '@/components/Profile/ProfileNavBarLogOut/index.vue';
import ProfileNavBarSettings from '@/components/Profile/ProfileNavBarSettings/index.vue';

@Component({
  name: 'ProfileNavBar',
  components: { ProfileNavBarSettings, ProfileNavBarLogOut, ProfileNavBarLinks }
})
export default class ProfileNavBar extends Vue {
  @Prop({ required: true }) profileRoutes!: Location[];

  @Prop({ default: false }) profileRoutesStatic!: boolean;
}
