
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import ProfileNavBarLink from '@/components/Profile/ProfileNavBarLink/index.vue';
import { client } from '@/store/modules/client';
import ProfileNavBarSettings from '@/components/Profile/ProfileNavBarSettings/index.vue';
import ProfileNavBarLogOut from '@/components/Profile/ProfileNavBarLogOut/index.vue';

@Component({
  name: 'ProfileNavBarLinks',
  components: { ProfileNavBarLogOut, ProfileNavBarSettings, ProfileNavBarLink }
})
export default class ProfileNavBarLinks extends Vue {
  @Prop({ required: true }) profileRoutes!: Location[];

  @Prop({ default: false }) profileRoutesStatic!: boolean;

  client = client;

  listOpened = false;

  get opened() {
    return this.listOpened || this.profileRoutesStatic;
  }

  @Watch('$route')
  onRouteChange() {
    this.listOpened = false;
  }
}
