import { FieldData } from '@/components/registration/models/field';
import { DisbursementBankAccount, DisbursementCard } from '@/api/models/disbursement';
import { Address } from '@/api/models/address';
import { AddressResponse, ResponseMap } from '@/api/client';
import { IdentityDocument } from '@/api/models/itentityDocument';

export interface PersonalDataFormPayload {
  username: string;
  email: string;
  mobilePhone: string;
}

export interface PersonalDataPayload extends PersonalDataFormPayload {
  companyId: string;
  password: string;
  mobilePhoneVerificationId: string;
}

export interface AdditionalInfo {
  maritalStatus: string;
  numberOfChildren: string;
  additionalContactName: string;
}

export interface ProfileEducationInterface {
  stage: string;
}

export interface BankStatement {
  startDate: string;
  endDate: string;
  turnover?: number;
}

export interface ProfileWorkInfoInterface {
  companyName?: string;
  position?: string;
  employmentStatus: string;
  industry?: string;
}

export interface ProfileDesiredLoanInterface {
  loanAmount: string;
  term: string;
  loanPurpose: string;
  productId: string;
}

export enum PROFILE_ADDITIONAL_INFO_KEYS {
  EDUCATION = 'education',
  WORK_INFO = 'work-info',
  FAMILY = 'family',
  BANK_STATEMENT = 'bank-statement',
  DESIRED_LOAN = 'desired-loan'
}

export enum PROFILE_SECTIONS {
  IDENTITY_DOCUMENTS = 'sections[identityDocuments]',
  ADDRESSES = 'sections[addresses]'
}

export type ProfileSections = Partial<Record<PROFILE_SECTIONS, string>>;

export interface ProfileAdditionalInfo {
  [PROFILE_ADDITIONAL_INFO_KEYS.FAMILY]?: AdditionalInfo;
  [PROFILE_ADDITIONAL_INFO_KEYS.EDUCATION]?: ProfileEducationInterface;
  [PROFILE_ADDITIONAL_INFO_KEYS.WORK_INFO]?: ProfileWorkInfoInterface;
  [PROFILE_ADDITIONAL_INFO_KEYS.DESIRED_LOAN]?: ProfileDesiredLoanInterface;
  [PROFILE_ADDITIONAL_INFO_KEYS.BANK_STATEMENT]?: BankStatement;
}

export interface ProfileAdditionalInfoPayload {
  data: ProfileAdditionalInfo;
}

export interface AdditionalInfoDataPayload {
  marketing: Record<string, unknown>;
}

export interface AdditionalInfoPayload {
  data: AdditionalInfoDataPayload;
}

export interface ClientCreatePayload extends PersonalDataPayload {
  consents: {
    [key: string]: boolean;
  };
  additionalInfo?: AdditionalInfoPayload;
}

interface ProfileCreditLimit {
  maxAmount: string;
  maxPayment: string;
}

export interface Profile {
  id: string;
  companyId: string;
  username: string;
  usernameVerificationStatus: VERIFICATION_STATUS;
  email: string;
  mobilePhone: string;
  emailVerified: boolean;
  livelinessVerificationStatus: VERIFICATION_STATUS;
  mobilePhoneVerified: boolean;
  personalInfo: PersonalInfo;
  additionalInfo: ProfileAdditionalInfo;
  creditLimit: ProfileCreditLimit;
  availableCredit: string | null;
  agreementSigningMethod?: ClientSigningAgreement;
  identityDocuments?: IdentityDocument[];
  addresses?: AddressResponse[];
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female'
}

interface Name {
  firstName: string;
  lastName: string;
  middleName: string;
}

export interface PersonalInfo {
  name: Name;
  birthDate: string;
  gender: string;
}

export interface PersonalData {
  personalInfo: PersonalInfo;
  identityDocument: IdentityDocument;
}

export interface FinancesData {
  workInfo: ProfileWorkInfoInterface;
  bankStatement: BankStatement;
}

export enum VERIFICATION_STATUS {
  UNKNOWN = 'unknown',
  REJECT = 'reject',
  CONFIRMED = 'confirmed'
}
export enum TOKENS {
  ACCESS_TOKEN = 'access_token',
  EXPIRES_IN = 'expires_in',
  REFRESH_TOKEN = 'refresh_token',
  REFRESH_EXPIRES_IN = 'refresh_expires_in',
  TOKEN_TYPE = 'token_type',
  NOT_BEFORE_POLICY = 'not-before-policy',
  SESSION_STATE = 'session_state',
  SCOPE = 'scope'
}

export interface Tokens {
  [TOKENS.ACCESS_TOKEN]: string;
  [TOKENS.EXPIRES_IN]?: number;
  [TOKENS.REFRESH_TOKEN]?: string;
  [TOKENS.REFRESH_EXPIRES_IN]?: number;
  [TOKENS.TOKEN_TYPE]?: string;
  [TOKENS.NOT_BEFORE_POLICY]?: number;
  [TOKENS.SESSION_STATE]?: string;
  [TOKENS.SCOPE]?: string;
}

export interface AddressesMap {
  declared: Address;
  living: Address | null;
  matchesDeclaredAddress: boolean;
}

export type DisbursementsResponseMap = ResponseMap<DisbursementBankAccount> & ResponseMap<DisbursementCard>;

export interface ClientChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface ClientChangeEmail {
  email: string;
  verificationId: string;
}

export interface ClientChangePhone {
  mobilePhone: string;
  verificationId: string;
}

export interface ClientSigningAgreement {
  type: string;
  data?: FieldData;
}

export interface PasswordResetParams {
  verificationId: string;
  identity: string;
  type: string;
}
