import { FieldData, FieldValue } from '@/components/registration/models/field';
import { client } from '@/store/modules/client';
import dayjs from 'dayjs';
import { stringifyFieldValue } from './helpers';

export type FieldRule<TValue = FieldValue> = (value: TValue) => string;
export type FieldGroupRule = (value: FieldData) => { [key: string]: string };

export enum FORM_ERRORS {
  FIELD_ERROR_DEFAULT = 'FIELD_ERROR_DEFAULT',
  FIELD_ERROR_AMOUNT_LIMIT = 'FIELD_ERROR_AMOUNT_LIMIT',
  FIELD_ERROR_AVAILABLE_CREDIT = 'FIELD_ERROR_AVAILABLE_CREDIT',
  FIELD_ERROR_MAX_PAYMENT = 'FIELD_ERROR_MAX_PAYMENT',
  FIELD_ERROR_NON_FINAL_APPLICATION_STATE = 'FIELD_ERROR_NON_FINAL_APPLICATION_STATE',
  FIELD_ERROR_NON_FINAL_AGREEMENT_STATE = 'FIELD_ERROR_NON_FINAL_AGREEMENT_STATE',
  FIELD_ERROR_REQUIRED = 'FIELD_ERROR_REQUIRED',
  FIELD_ERROR_ONLY_NUMBERS = 'FIELD_ERROR_ONLY_NUMBERS',
  FIELD_ERROR_EMAIL_REGEX = 'FIELD_ERROR_EMAIL_REGEX',
  FIELD_ERROR_PHONE_NUMBER_FORMAT = 'FIELD_ERROR_PHONE_NUMBER_FORMAT',
  FIELD_ERROR_ID_NUMBER_FORMAT = 'FIELD_ERROR_ID_NUMBER_FORMAT',
  FIELD_ERROR_ID_NUMBER_NOT_EXIST = 'FIELD_ERROR_ID_NUMBER_NOT_EXIST',
  FIELD_ERROR_CLIENT_IS_BLOCKED = 'FIELD_ERROR_CLIENT_IS_BLOCKED',
  HELP_LINK_TEXT = 'HELP_LINK_TEXT',
  FIELD_ERROR_PASSWORD_FORMAT = 'FIELD_ERROR_PASSWORD_FORMAT',
  FIELD_ERROR_PASSPORT_ID = 'FIELD_ERROR_PASSPORT_ID',
  FIELD_ERROR_DATE_FORMAT = 'FIELD_ERROR_DATE_FORMAT',
  FIELD_ERROR_DATE = 'FIELD_ERROR_DATE',
  FIELD_ERROR_DATE_IS_PAST = 'FIELD_ERROR_DATE_IS_PAST',
  FIELD_ERROR_DATE_IS_FUTURE = 'FIELD_ERROR_DATE_IS_FUTURE',
  FIELD_ERROR_IS_CLIENT_PHONE_NUMBER = 'FIELD_ERROR_IS_CLIENT_PHONE_NUMBER',
  FIELD_ERROR_FINANCE_MIN_FOUR = 'FIELD_ERROR_FINANCE_MIN_FOUR',
  FIELD_ERROR_FINANCE_MIN_FIVE = 'FIELD_ERROR_FINANCE_MIN_FIVE',
  FIELD_ERROR_FINANCE_MAX_ADDITIONAL_INCOME = 'FIELD_ERROR_FINANCE_MAX_ADDITIONAL_INCOME',
  FIELD_ERROR_BANK_ACCOUNT_NUMBER_FORMAT = 'FIELD_ERROR_BANK_ACCOUNT_NUMBER_FORMAT',
  FIELD_ERROR_BANK_CARD_VERIFICATION_EXPIRED = 'FIELD_ERROR_BANK_CARD_VERIFICATION_EXPIRED',
  FIELD_ERROR_VERIFICATION_RESTORE_FAILED = 'FIELD_ERROR_VERIFICATION_RESTORE_FAILED',
  FIELD_ERROR_VERIFICATION_CREATE = 'FIELD_ERROR_VERIFICATION_CREATE',
  FIELD_ERROR_CONFIRM_AMOUNT_400 = 'FIELD_ERROR_CONFIRM_AMOUNT_400',
  FIELD_ERROR_CONFIRM_AMOUNT_409 = 'FIELD_ERROR_CONFIRM_AMOUNT_409',
  FIELD_ERROR_CONFIRM_AMOUNT_410 = 'FIELD_ERROR_CONFIRM_AMOUNT_410',
  FIELD_ERROR_BANK_CARD_NOT_VERIFIED = 'FIELD_ERROR_BANK_CARD_NOT_VERIFIED',
  FIELD_ERROR_WRONG_AGE = 'FIELD_ERROR_WRONG_AGE',
  FIELD_ERROR_NO_LETTER = 'FIELD_ERROR_NO_LETTER',
  FIELD_ERROR_USERNAME_NOT_UNIQUE = 'Client with such code already exists',
  FIELD_ERROR_EMAIL_NOT_UNIQUE = 'Client with such email already exists',
  FIELD_ERROR_PHONE_NOT_UNIQUE = 'Client with such mobile phone already exists',
  FIELD_ERROR_WRONG_VERIFICATION_CODE = 'Invalid verification code',
  FIELD_ERROR_INVALID_IIN = 'Invalid format Reason',
  FIELD_ERROR_INVALID_IBAN = 'This is not a valid International Bank Account Number (IBAN)',
  FIELD_ERROR_INVALID_PHONE_NUMBER = 'This value is not a valid phone number',
  FIELD_ERROR_NEW_PASSWORD_SAME_AS_OLD = 'FIELD_ERROR_NEW_PASSWORD_SAME_AS_OLD',
  FIELD_ERROR_PASSWORD_IS_NOT_SAME = 'FIELD_ERROR_PASSWORD_IS_NOT_SAME',
  FIELD_ERROR_WRONG_CURRENT_PASSWORD = 'Invalid current password',
  FIELD_ERROR_WRONG_CLIENT_IDENTIFIER_EMAIL = 'Invalid email identifier',
  FIELD_ERROR_WRONG_CLIENT_IDENTIFIER_PHONE = 'Invalid phone identifier',
  COID_VERIFICATION_FAILED = 'COID_VERIFICATION_FAILED',
  BANK_STATEMENT_END_DATE_BEFORE_START_DATE = 'BANK_STATEMENT_END_DATE_BEFORE_START_DATE',
  BANK_STATEMENT_FILES_REQUIRED = 'BANK_STATEMENT_FILES_REQUIRED',
  BANK_STATEMENT_LIMIT_REACHED = 'BANK_STATEMENT_LIMIT_REACHED',
  BANK_STATEMENT_UPLOAD_ERROR = 'BANK_STATEMENT_UPLOAD_ERROR',
  BANK_STATEMENT_FILE_TYPE = 'BANK_STATEMENT_FILE_TYPE',
  BANK_STATEMENT_FILE_SIZE = 'BANK_STATEMENT_FILE_SIZE'
}

export const required: FieldRule = value => {
  return value ? '' : FORM_ERRORS.FIELD_ERROR_REQUIRED;
};

export const numeric: FieldRule = value => {
  const num = stringifyFieldValue(value);
  return /^-?\d+$/.test(num) ? '' : FORM_ERRORS.FIELD_ERROR_ONLY_NUMBERS;
};

export const emailFormat: FieldRule = value => {
  const email = stringifyFieldValue(value);
  // eslint-disable-next-line
  const REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return REGEX.test(email) ? '' : FORM_ERRORS.FIELD_ERROR_EMAIL_REGEX;
};

export const isDatePast: FieldRule = value => {
  const today = dayjs();
  const date = stringifyFieldValue(value);
  return today.isAfter(date) ? '' : FORM_ERRORS.FIELD_ERROR_DATE_IS_FUTURE;
};

export const isDateFuture: FieldRule = value => {
  const today = dayjs();
  const date = stringifyFieldValue(value);
  return today.isBefore(date) ? '' : FORM_ERRORS.FIELD_ERROR_DATE_IS_PAST;
};

export const phoneNumberFormat: FieldRule = value => {
  const phone = stringifyFieldValue(value);
  return /^[+]\d{11}$/.test(phone) ? '' : FORM_ERRORS.FIELD_ERROR_PHONE_NUMBER_FORMAT;
};

export const idNumberFormat: FieldRule = value => {
  const id = stringifyFieldValue(value);
  return id.length === 12 ? '' : FORM_ERRORS.FIELD_ERROR_ID_NUMBER_FORMAT;
};

export const passwordFormat: FieldRule = value => {
  const passw = stringifyFieldValue(value);
  return /^[a-zA-Z0-9]{8,32}$/.test(passw) ? '' : FORM_ERRORS.FIELD_ERROR_PASSWORD_FORMAT;
};

export const passportIdFormat: FieldRule = value => {
  const docId = stringifyFieldValue(value);
  return docId.length === 9 ? '' : FORM_ERRORS.FIELD_ERROR_PASSPORT_ID;
};

export const allChecked: FieldGroupRule = value => {
  return Object.keys(value).reduce((total, current) => {
    const error = required(value[current]);

    if (error) {
      total[current] = error;
    }

    return total;
  }, {} as { [key: string]: string });
};

export const bankAccountNumberFormat: FieldRule = value => {
  const accId = stringifyFieldValue(value);
  return /^KZ\d{5}[0-9A-Z]{13}$/.test(accId) ? '' : FORM_ERRORS.FIELD_ERROR_BANK_ACCOUNT_NUMBER_FORMAT;
};

export const clientPhoneNumber: FieldRule = value => {
  const clientsPhoneNumber = client.profile.mobilePhone;

  return clientsPhoneNumber !== value ? '' : FORM_ERRORS.FIELD_ERROR_IS_CLIENT_PHONE_NUMBER;
};

export const financeMinFiveDigit: FieldRule = value => {
  const fin = stringifyFieldValue(value);
  return fin.length < 5 && value ? FORM_ERRORS.FIELD_ERROR_FINANCE_MIN_FIVE : '';
};

// TODO: keep static numbers(18, 68) in constants
export const MIN_AGE = 18;
export const MAX_AGE = 68;
export const ageValidator: FieldRule = value => {
  const date = stringifyFieldValue(value);
  const age = dayjs().diff(date, 'year');
  return age >= MIN_AGE && age <= MAX_AGE ? '' : FORM_ERRORS.FIELD_ERROR_WRONG_AGE;
};

export const hasLetter: FieldRule = value => {
  const text = stringifyFieldValue(value);
  return /[\u0400-\u04FF-]/.test(text) ? '' : FORM_ERRORS.FIELD_ERROR_NO_LETTER;
};
