import request, { HTTP_METHODS } from '@/utils/request';
import { AxiosPromise } from 'axios';
import { ApplicationPayload, Application, OfferVariation, ActionPayload } from './models/application';
import { makeVoid } from '@/utils';

export const getApplications = (): AxiosPromise<Application[]> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/application/applications/loan',
    method: HTTP_METHODS.GET
  });

export const createApplication = (data: ApplicationPayload): AxiosPromise<Application> =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: '/application/applications/loan',
    method: HTTP_METHODS.POST,
    data,
    headers: {
      accept: 'application/vnd.sunfinance.v2+json'
    }
  });

export const confirmApplication = (applicationId: string) =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/application/applications/loan/${applicationId}/confirmation`,
    method: HTTP_METHODS.PUT
  }).then(makeVoid);

export const transitToAdditionalAction = (applicationId: string, data: ActionPayload) =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/application/applications/loan/${applicationId}/additional-action`,
    method: HTTP_METHODS.PUT,
    data: data
  }).then(makeVoid);

export const cancelApplication = (applicationId: string, reason: string) =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/application/applications/loan/${applicationId}/cancellation`,
    method: HTTP_METHODS.PUT,
    data: { text: reason }
  }).then(makeVoid);

export const acceptOffer = (applicationId: string, data: OfferVariation) =>
  request({
    baseURL: process.env.VUE_APP_BASE_API_AUTH,
    url: `/application/applications/loan/${applicationId}/accept-offer`,
    method: HTTP_METHODS.PUT,
    data
  }).then(makeVoid);
