
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'DocumentsPage'
})
export default class DocumentsPage extends Vue {
  get documents() {
    return [
      {
        title: this.$t('DOCUMENTS.REGISTRATION_FEE_NOTICE').toString(),
        link: '/docs/Уведомление об учетной регистрации от 07.08.2020г..pdf'
      },
      {
        title: this.$t('DOCUMENTS.PERSONAL_DATA_COLLECT_CONSENT').toString(),
        link: '/docs/с изменениями Салем  согласие перс данные.pdf'
      },
      {
        title: this.$t('DOCUMENTS.PERSONAL_DATA_PROCESS_CONSENT').toString(),
        link: '/docs/Политика в отношении оброботки персон данных Салем.docx'
      },
      {
        title: this.$t('DOCUMENTS.VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_15_08_2022').toString(),
        link: '/docs/Правила добровольного онлайн страхования от несчастного случая, СД от 05.03.2021г.(с изменениями на 15.04.2024).pdf'
      },
      {
        title: this.$t('DOCUMENTS.VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE_15_08_2022').toString(),
        link: '/docs/Правила добровольного онлайн страхования жизни, СД от 15.08.2022 (с изменениями на 15.04.2024 г.).pdf'
      },
      {
        title: this.$t('DOCUMENTS.VOLUNTARY_ONLINE_ACCIDENT_INSURANCE_LAST').toString(),
        link: '/docs/Правила доб онлайн страхования от НС от 05032021 с изменениями на 02052023.pdf'
      },
      {
        title: this.$t('DOCUMENTS.VOLUNTARY_ONLINE_ACCIDENT_INSURANCE').toString(),
        link: '/docs/Правила добровольного онлайн страхования заемщика от НС.docx'
      },
      {
        title: this.$t('DOCUMENTS.VOLUNTARY_ONLINE_CREDIT_LIFE_INSURANCE').toString(),
        link:
          '/docs/Правила добровольного онлайн страхования жизни заемщика от 27.07.2020 (с изменениями на 12.10.2020).docx'
      },
      {
        title: this.$t('DOCUMENTS.RULES_MICROCREDIT').toString(),
        link: '/docs/Правила предоставления микрокредитов 01.09.2022.docx'
      },
      {
        title: this.$t('DOCUMENTS.MICROFINANCE_LICENSE').toString(),
        link: '/docs/Лицензия на осуществление микрофинансовой деятельности от 31.03.2021.pdf'
      }
    ];
  }
}
