
import { Component, Vue } from 'vue-property-decorator';
import HeaderComponent from '@/components/Header.vue';
import FooterComponent from '@/components/Footer.vue';
import { client } from '@/store/modules/client';
import ProfileNavBar from '@/components/Profile/ProfileNavBar/index.vue';
import { Location } from 'vue-router';
import { PROFILE_ROUTES } from '@/router/routes_type';
import { chatToken } from '@/utils';

@Component({
  components: { ProfileNavBar, HeaderComponent, FooterComponent }
})
export default class AppComponent extends Vue {
  client = client;

  transparentRoutes = ['Home', 'CreditForBusinessPage'];

  profileRoutes: Location[] = [
    {
      name: PROFILE_ROUTES.MY_LOAN_PAGE
    },
    {
      name: PROFILE_ROUTES.DOCUMENTS_PAGE
    },
    {
      name: PROFILE_ROUTES.CHANGE_PASSWORD_PAGE
    },
    {
      name: PROFILE_ROUTES.CHANGE_EMAIL_PAGE
    },
    {
      name: PROFILE_ROUTES.CHANGE_PHONE_PAGE
    }
  ];

  transparent(): boolean {
    const name = this.$router.currentRoute.name || 'Home';
    return this.transparentRoutes.includes(name);
  }

  profileNavBarStatic() {
    const currentRouteName = this.$router.currentRoute.name || '';
    if (currentRouteName === PROFILE_ROUTES.EDIT_PAGE) {
      return true;
    }
    return this.profileRoutes.map(route => route.name).includes(currentRouteName);
  }

  mounted() {
    client.initProfile();
    this.initializeChat();
  }

  initializeChat() {
    const token = chatToken();

    if (!token || token.length === 0) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://livechat-02.chat2desk.kz/packs/ie-11-support.js';
    document.body.appendChild(script);

    window.chat24_token = token;
    window.chat24_url = 'https://livechat-02.chat2desk.kz';
    window.chat24_socket_url = 'wss://livechat-02.chat2desk.kz/widget_ws_new';
    window.chat24_show_new_wysiwyg = 'true';
    window.chat24_static_files_domain = 'https://storage-02.chat2desk.kz//';
    window.lang = 'ru';

    fetch(''.concat(window.chat24_url, '/packs/manifest.json?nocache=').concat(new Date().getTime().toString()))
      .then(res => res.json())
      .then(data => {
        const chat24 = document.createElement('script');
        chat24.type = 'text/javascript';
        chat24.async = true;
        chat24.src = ''.concat(window.chat24_url).concat(data['application.js']);
        document.body.appendChild(chat24);
      });
  }
}
