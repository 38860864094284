import { asyncMiddleware } from '@/router';
import { PROFILE_ROUTES } from '@/router/routes_type';
import { client } from '@/store/modules/client';
import { applications } from '@/store/modules/application';
import { APPLICATION_STATUS } from '@/api/models/application';
import { getAgreementList } from '@/api/agreements';
import { AGREEMENT_PROCESSING_STATUS } from '@/api/models/agreements';
import { agreements } from '@/store/modules/agreements';

export const initLoanMiddleware: asyncMiddleware = async () => {
  if (!(await agreements.initGetAgreementList())) {
    return { name: PROFILE_ROUTES.NO_LOAN_PAGE };
  }

  try {
    await client.initProfileDisbursements();
  } catch {
    return;
  }
};

export const noActiveAgreementsMiddleware: asyncMiddleware = async () => {
  await applications.initGetApplications();

  const approvedApplications = applications.getApplications.filter(application => {
    return application.status === APPLICATION_STATUS.APPROVED;
  });

  for (const application of approvedApplications) {
    const { data: agreements } = await getAgreementList({ applicationId: application.id });
    const hasAgreements = agreements.length > 0;
    const hasActiveAgreement = agreements.some(agreement => {
      return agreement.processingStatus !== AGREEMENT_PROCESSING_STATUS.CLOSED;
    });

    if (!hasAgreements || hasActiveAgreement) {
      return { name: PROFILE_ROUTES.MY_LOAN_PAGE };
    }
  }
};
